.iphone-viewer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.viewer-with-arrows {
  display: flex;
  align-items: center;
  gap: 20px;
}

.arrow-button {
  background: none;
  border: none;
  color: #D43C8F;
  font-size: 3rem;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.2s ease, color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.arrow-button:hover {
  transform: scale(1.1);
  color: #c0357e;
}

.arrow-button:focus {
  outline: none;
}

.iphone-frame {
  width: 300px;
  height: 650px;
  background-color: #1a1a1a;
  border-radius: 45px;
  padding: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.iphone-screen {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 38px;
  overflow: hidden;
  position: relative;
  touch-action: pan-x pan-y pinch-zoom;
}

.demo-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
}

.dots-container {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 15px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #D3D3D3;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #D43C8F;
}

@media (max-width: 768px) {
  .iphone-frame {
    width: 250px;
    height: 542px;
  }
  
  .dot {
    width: 8px;
    height: 8px;
  }

  .arrow-button {
    font-size: 2rem;
    width: 40px;
    height: 40px;
  }

  .viewer-with-arrows {
    gap: 10px;
  }
} 