.App {
  background-color: white;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
}

.logo {
  width: 100%;
  max-width: 200px;
  height: auto;
  margin-bottom: 10px;
}

.main-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-top: 10px;
}

.tagline {
  font-family: 'Pacifico';
  color: #D43C8F;
  text-align: center;
  margin: 10px 0 10px 0;
  font-size: 1.8rem;
  max-width: 800px;
  font-weight: 200;
}


.sub-tagline {
  font-family: 'Arial';
  color: #D43C8F;
  text-align: center;
  margin: 30px 0 10px 0;
  font-size: 1.5rem;
  max-width: 800px;
  font-weight: bold;
}

.app-store-button {
  transition: transform 0.2s ease;
}

.app-store-button img {
  width: 200px;
  height: auto;
}

.app-store-button:hover {
  transform: scale(1.05);
}

.waitlist-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.waitlist-text {
  font-size: 1rem;
  color: #333;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 300;
  margin-top: 20px;
}

.waitlist-button {
  background-color: #D43C8F;
  color: white;
  padding: 12px 24px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 1.1rem;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.waitlist-button:hover {
  transform: scale(1.05);
  background-color: #c0357e;
}

.footer {
  margin-top: 30px;
  text-align: center;
}

.footer-text {
  font-size: 1rem;
  color: #333;
}

.sub-title {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  text-align: center;
}

.sub-text {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #333;
  font-weight: 300;
  text-align: center;
}

.try-it-out {
  margin-top: 30px;
  font-size: 1.5rem;
  color: #c0357e;
  font-weight: bold;
  text-align: center;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .container {
    padding: 15px;
  }
  
  .logo {
    max-width: 150px;
    margin-bottom: 10px;
  }

  .main-image {
    max-width: 200px;
  }
  
  .tagline {
    margin-top: 20px !important;
    font-size: 1.5rem;
    margin: 0 0 20px 0;
  }

  .sub-tagline {
    font-size: 1.2rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  .app-store-button img {
    width: 160px;
  }

  .waitlist-text {
    font-size: 1rem;
  }

  .waitlist-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}
